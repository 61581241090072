<template>
  <div>
    <Modal :options="{width: '30vw', close: true}" @close="cerrar_modal">
      <form @submit.prevent="guardar_opcion">
        <div class="title">{{ (op.id ? 'Editar' : 'Crear')+' opción' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="op.nombre" id="nombre" name="nombre" type="text" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="valor" class="col-form-label col-sm-2">Valor</label>
            <div class="col-sm-10"><input v-model="op.valor" id="valor" name="valor" type="text" class="form-control"></div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button class="btn btn-primary mr-2">Guardar opción</button>
              <button class="btn btn-danger" type="button" @click="cerrar_modal">Cancelar</button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  export default {
    components: {
      Modal
    }
    ,props: {
      opcion: {
        type: Object
        ,default: () => ({
          nombre: null
          ,valor: null
        })
      }
    }
    ,data: () => ({
      op: {
        nombre: null
        ,valor: null
      }
    })
    ,mounted: function() {
      this.op = this.opcion;
    }
    ,methods: {
      guardar_opcion: function() {
        this.$emit('agregar',this.op);
      }
      ,cerrar_modal: function() {
        this.$emit('close')
      }
    }
  }
</script>